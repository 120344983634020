<template>
    <div>
		<b-modal ref="modal_send_quotation" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("action.envoyer") }}
				</template>
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-else>
				<div class="col-12">
					<div class="form-group">
						<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
						<e-select
							v-model="template"
							id="model_id"
							track-by="model_label"
							label="model_label"
							:placeholder="labelTitleTemplate"
							:deselectLabel="deselectLabel"
							:selectedLabel="selectedLabel"
							:options="templates"
							:searchable="true"
							:allow-empty="false"
							:show-labels="false"
						>
							<template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</div>
					
					<div class="form-group mt-4">
						<label for="mailtype_label">{{ $t('model.expeditor_label') }} *</label>
						<e-select
							v-model="finalExpeditor"
							id="expeditor_id"
							:placeholder="labelTitleExpeditor"
							:options="finalExpeditors"
							:searchable="true"
							:allow-empty="false"
							:show-labels="false"        
						>
							<template slot="singleLabel" slot-scope="{ option }">{{ option }}</template>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</div>

					<b-alert show variant="warning" class="mt-2" v-if="nomail">
						<h6>{{ $t("model.personnaliser_email") }}</h6>
					</b-alert>

					<div class="form-group">
						<label for="commentaire" class="col-form-label">{{ $t("monte.commentaire") }}</label>
						<textarea id="commentaire" class="form-control" rows="3" v-model="commentaire"></textarea>
					</div>
					<div class="form-group">
						<span>
							{{ $t('global.destinataires') }}: {{ destinataires_mails }}
						</span>
					</div>

					<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("action.envoyer") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
				</div>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
	import Accounting from '@/mixins/Accounting.js'
	import Contact from '@/mixins/Contact.js'
	import Quotation from '@/mixins/Quotation.js'
	import Model from "@/mixins/Model.js"

	export default {
		name: "ModalSendDocumentDevis",
		mixins: [Accounting, Contact, Quotation, Model],
		props: ['quotation_id'],
		data () {
			return {
				templates: [],
				finalExpeditors: [],
				template: null,
				finalExpeditor: null,
				processing: false,
				document: null,
				canChooseName: true,
				nomail: false,
				addMailToLicence: this.getTrad("model.personnaliser_email"),
				commentaire: '',
				destinataires_mails: '',
				modal_loaded: false,

				deselectLabel: this.getTrad("global.press_enter_to_remove"),
				selectedLabel: this.getTrad("global.selected_label"),
				enter_to_select: this.getTrad("global.press_enter_to_select"),

				labelTitleFormat: this.getTrad("horse.search_format"),
				labelTitleTemplate: this.getTrad("horse.search_doc"),
				labelTitleExpeditor: this.getTrad("model.search_expeditor"),
			}
		},
		methods: {
			async init_component() {
				this.processing = false

				await this.getQuotationById(this.quotation_id)
				.then(async quotation => {
					if(quotation && quotation.contact) {
						// let tiers_mail = []
						// if(quotation.tiers.tiers_mail != '') {
						// 	tiers_mail.push(quotation.tiers.tiers_mail)
						// }
						// const comptables_mails = await this.getContactByTiersAndByFonction(quotation.tiers.tiers_id, 'COMPTA')
						// const all_mails = tiers_mail.concat(comptables_mails)
						this.destinataires_mails = quotation.contact.contact_mail
					}
					else {
						this.failureToast("invoice.devis.without_contact")
					}

					if(quotation && quotation.author) {
						let accounting_plan = await this.loadAccountingPlanByEntity(quotation.author.tiers_id)
						this.templates = await this.loadAllModel(accounting_plan.accountingplan_id, ['accounting_plan'], 'quotation_document_notification')
					}
				})

				const fromAddress = await this.loadAllFromAddress()
				await this.formatFromAddress(fromAddress)

				this.modal_loaded = true
			},
			async formatFromAddress(fromAddress){
				let array_mail = []
				let array_name = []
				this.finalExpeditors = []
				const selected = fromAddress["selected"]
				delete fromAddress["selected"]

				for (const [key, value] of Object.entries(fromAddress)){				
					if (value.licenceconfig_key === "from_address"){
						if (value.licenceconfig_value != '' || value.licenceconfig_value != null){
							const configValueAddress = value.licenceconfig_value.split(',')
							for (const address of configValueAddress){
								array_mail.push(address.trim())
							}
						} 					
					}
					if (value.licenceconfig_key === "from_name" ){
						if (value.licenceconfig_value != ''|| value.licenceconfig_value != null){
							const configValueName = value.licenceconfig_value.split(',')
							for (const name of configValueName){
								array_name.push(name.trim())
							}
						} 
					}
					if(array_mail.length > 0 && array_name.length > 0){
						if(array_name[0] === 'Groomy'){
							this.nomail = true
						}
						break;
					}
				}

				for (const index in array_mail){
					// if(array_mail[index] === selected["email"]){
					// 	this.finalExpeditors.unshift(array_mail[index]+ " / " + array_name[index])
					// } else {
					this.finalExpeditors.push(array_mail[index]+ " / " + array_name[index])
					// }
				}

				this.finalExpeditor = this.finalExpeditors[0]
			},
			openModal(doc) {
				this.document = doc
				this.$refs.modal_send_quotation.show()
			},
			closeModal() {
				this.$refs.modal_send_quotation.hide()
			},
			async checkForm() {
				if(this.template) {
					this.processing = true
					await this.sendMailDocumentQuotation(this.quotation_id, this.document.quotationdocument_id, "quotation_document_notification", this.template.model_id, this.commentaire, this.finalExpeditor)
					.then(result => {
						if(result) {
							this.processing = false
							this.$emit('submit')
							this.closeModal()
						}
						else {
							this.processing = false
							this.$emit('submit')
							this.closeModal()
						}
					})
				}
				else {
					this.failureToast("formulaire.erreur_champs_non_remplis")
				}
			}
		},
		components: {
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
		}
	}
</script>